import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='container'>
            <br />
            <br />
            <h3 style={{ whiteSpace: "pre-wrap" }}>
                <strong>Who are we?</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                ‘We’, ‘us’ and ‘our’ refer to&nbsp;
                <strong>Finstead Capital Pty Ltd ACN 638 390 182</strong>&nbsp;and our
                subsidiaries and related businesses.
            </p>
            <h3 style={{ whiteSpace: "pre-wrap" }}>
                <strong>How we commit to your privacy</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We understand how important it is to be careful with and protect your
                personal information. This document sets out our privacy policy commitment
                in respect of personal information we hold about you and what we do with
                that information.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                Our commitment in respect of personal information is to abide by the
                Australian Privacy Principles for the protection of personal information, as
                set out in the&nbsp;<em>Privacy Act 1988</em>&nbsp;and any other relevant
                laws.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We acknowledge that all personal information collected about you will only
                be used for the purposes we have collected it as allowed by law. It is
                important to us that you are confident that any personal information we hold
                about you will be treated in a way that protects your personal information.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Personal information</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                When we refer to&nbsp;
                <strong>
                <em>personal information</em>
                </strong>
                &nbsp;we mean information from which your identity is reasonably apparent.
                This information may include facts or an opinion about you.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                The kinds of personal information we may collect about you can include your
                name, date of birth, address, email address, phone number, account details,
                occupation, and any other information we made need to identify you.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                The personal information we collect and hold about you may include credit
                information. Credit information is information which is used to assess your
                eligibility to be provided with finance and may include any finance that you
                have outstanding, your repayment history in respect of those loans, and any
                defaults. Usually, credit information is exchanged between credit and
                finance providers and credit reporting bodies.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you are applying for finance we may also collect the ages and count of
                your dependants and cohabitants, the length of time you have spent at your
                current address, your employment details and proof of earnings and expenses.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you apply for employment with us we will collect information about your
                work history and ask your referees about you. If you want to make purchases
                from or through us we will also collect your payment information. We will
                also collect this information if we need to make payments to you. If you
                apply to become a Finstead referral partner we will collect information to
                enable us to ensure business can be concluded in a legal fashion.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>How do we collect personal information?</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We will seek to collect your personal information directly from you. We may
                also collect your personal information from credit reporting bodies, finance
                brokers and other people such as financial planners, vendors, accountants,
                lawyers and referees.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>What do we do with your personal information?</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We may disclose your personal information:
            </p>
            <ul data-rte-list="default">
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to prospective funders or other intermediaries in relation to your
                    finance requirements;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to anyone, where you have provided us consent;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    where we are required to do so by law, such as under the&nbsp;
                    <em>Anti-Money Laundering and Counter Terrorism Financing Act 2006</em>
                    &nbsp;or in accordance with a subpoena or summons issued by a court;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to organisations that are involved in administering your finance such as
                    third party suppliers, printing and postal services, call centres,
                    lenders mortgage insurers, trade insurers and credit reporting bodies;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to associated businesses that want to market products to you;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to companies that provide information and infrastructure systems to us;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to anybody who represents you, such as finance brokers, lawyers,
                    financial advisers and accountants;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to investors, agents, insurers or advisers, or any entity that has an
                    interest in our business; or
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    to your employer, referees or identity verification services.
                </p>
                </li>
            </ul>
            <p style={{ whiteSpace: "pre-wrap" }}>
                Prior to disclosing any of your personal information to another person or
                organisation, we will take all reasonable steps to be sure that:
            </p>
            <ol data-rte-list="default">
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    the person or organisation has a commitment to protecting your personal
                    information at least equal to our commitment, or
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    you have provided us consent to make the disclosure.
                </p>
                </li>
            </ol>
            <p style={{ whiteSpace: "pre-wrap" }}>
                Where we use cloud storage to store personal information that we hold. The
                cloud storage and the IT servers may be located outside Australia.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We may disclose your personal information to overseas entities that provide
                support functions to us. You may obtain more information about these
                entities by contacting us.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Why we need to collect personal information</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We primarily collect personal information to allow us to assess and manage
                the process and ongoing management of your finance needs. We also collect
                your personal information for the purposes of direct marketing and managing
                our relationship with you and, on occasion, we may offer you various
                products and services.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you apply to become a referring partner with us or apply for employment
                with us we will collect information about you to assist us to decide whether
                to appoint you.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We can collect payment information in order to process your payments.
            </p>
            <p
                className
                style={{ whiteSpace: "pre-wrap" }}
                id="yui_3_17_2_1_1630265928859_126"
            >
                To enable us to maintain a successful business relationship with you, we may
                disclose your personal information to other organisations that provide
                products or services used or marketed by us. The types of organisations to
                which we are likely to disclose information about you include other finance
                intermediaries, our insurance partners, lenders, valuers, accountants,
                credit reporting bodies, recoveries firms, debt collectors and lawyers. We
                may also choose to disclose your personal information to any other
                organisation that may have or is considering having an interest in your
                finance, or in our business.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>What type of Credit information we collect</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We may collect the following kinds of credit information and exchange this
                information with credit reporting bodies and other entities:
            </p>
            <ul data-rte-list="default">
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    credit liability information being information about your existing
                    finance which includes the name of the credit provider, whether the
                    credit provider holds an Australian Credit Licence, the type of finance,
                    the day the finance is entered into, the terms and conditions of the
                    finance, the maximum amount of finance available, and the day on which
                    the finance was terminated;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    repayment history information which is information about whether you
                    meet your repayments on time;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    information about the type of finance that you are applying for;
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    default and payment information; and
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    court proceedings information.
                </p>
                </li>
            </ul>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We exchange this credit information for the purposes of assessing your
                application for finance and managing that finance.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                When we obtain credit information from a credit reporting body about you, we
                may also seek publicly available information and information about any
                serious credit infringement that you may have committed.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Direct marketing</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                From time to time we may use your personal information to provide you with
                current information about finance offers you may find of interest, changes
                to our organisation, or new products or services being offered by us or any
                company with whom we are associated.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you do not wish to receive marketing information, you may at any time
                decline to receive such information by emailing us at&nbsp;
                <a href="mailto:privacy@finstead.com.au">privacy@finstead.com.au</a>.
                If the direct marketing is by email you may also use the unsubscribe
                function. We will not charge you for giving effect to your request and will
                take all reasonable steps to meet your request within a reasonable
                timeframe.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Updating your personal information</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                It is important to us that the personal information we hold about you is
                accurate and up to date. During the course of our relationship with you we
                may ask you to inform us if any of your personal information has changed.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you wish to make any changes to your personal information, you may
                contact us. We will generally rely on you to ensure the information we hold
                about you is accurate and complete.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>
                Access and correction to your personal and credit information
                </strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We will provide you with access to the personal and credit information we
                hold about you. You may request access to any of the personal information we
                hold about you at any time.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We may charge a fee for our costs of retrieving and supplying the
                information to you.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We aim to respond within 30 days of receiving your request. We may need to
                contact other entities to properly investigate your request.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                There may be situations where we are not required to provide you with access
                to your personal information, for example, if the information relates to
                existing or anticipated legal proceedings, or if your request is vexatious.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                An explanation will be provided to you if we deny you access to the personal
                information we hold about you.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If any of the personal information we hold about you is incorrect,
                inaccurate or out of date you may request that we correct the information.
                If appropriate we will correct the personal information. We aim to provide
                you with details about whether we have corrected the personal information
                within 30 days of receiving your request.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We may need to consult with other entities as part of our investigation.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If we refuse to correct personal information we will provide you with our
                reasons for not correcting the information.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>What is a Notifiable matter</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                The law requires us to advise you of ‘notifiable matters’ in relation to how
                we may use your credit information. You may request to have these notifiable
                matters (and this privacy policy) provided to you in an alternative form.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We exchange your credit information with credit reporting bodies. We use the
                credit information that we exchange with the credit reporting body to assess
                your creditworthiness, assess your application for finance and manage your
                finance.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you fail to meet your payment obligations in relation to any finance that
                we have provided or arranged or you have committed a serious credit
                infringement then we may disclose this information to a credit reporting
                body.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                You have the right to request access to the credit information that we hold
                about you and make a request for us to correct that credit information if
                needed. Please see the heading&nbsp;
                <em>Access and correction to your personal and credit information</em>
                &nbsp;below.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                Sometimes your credit information will be used by credit reporting bodies
                for the purposes of ‘pre-screening’ credit offers on the request of other
                credit providers. You can contact the credit reporting body at any time to
                request that your credit information is not used in this way.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                You may contact the credit reporting body to advise them that you believe
                that you may have been a victim of fraud. For a period of 21 days after the
                credit reporting body receives your notification the credit reporting body
                must not use or disclose that credit information. You can contact any of the
                following credit reporting bodies for more information:
            </p>
            <ul data-rte-list="default">
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    equifax.com.au,
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    dnb.com.au, or
                </p>
                </li>
                <li>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    experian.com.au.
                </p>
                </li>
            </ul>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Using government identifiers</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                When we collect government identifiers, such as your tax file number, we do
                not use or disclose this information other than as required by law. We will
                never use a government identifier to identify you.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Business without identifying you</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                In most circumstances it will be necessary for us to identify you in order
                to successfully do business with you, however, where it is lawful and
                practicable to do so, we will offer you the opportunity of doing business
                with us without providing us with personal information, for example, if you
                make general inquiries about interest rates or current promotional offers.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Sensitive information</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We will only collect sensitive information about you with your consent.
                Sensitive information is personal information that includes information
                relating to your racial or ethnic origin, political persuasion, memberships
                in trade or professional associations or trade unions, sexual preferences,
                criminal record, or health.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>
                How safe and secure is your personal information that we hold?
                </strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We will take reasonable steps to protect your personal information by
                storing it in a secure environment. We may store your personal information
                in paper and electronic form. We will also take reasonable steps to protect
                any personal information from misuse, loss and unauthorised access,
                modification or disclosure.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Complaints</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                If you are dissatisfied with how we have dealt with your personal
                information, or you have a complaint about our compliance with the Privacy
                Act, you may contact our complaints officer by email at&nbsp;
                <a href="mailto:complaints@afgonline.com.au">privacy@finstead.com.au</a>.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                We will seek to acknowledge your complaint within seven days and make a
                decision on your complaint within 30 days.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                If this does does remedy your complaint you are able to contact our External
                Dispute Resolution Scheme, the Credit and Investments Ombudsman (CIO)
                Australian Financial Complaints Authority (AFCA) which can be contacted on
                either&nbsp;<a href="http://www.afca.org.au/">www.afca.org.au</a>&nbsp;or
                1800 931 678; or the Office of the Australian Information Commissioner
                (OAIC) which can be contacted on either&nbsp;
                <a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>&nbsp;or 1300 363 992.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Further information</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                You may request further information about the way we manage your personal or
                credit information by contacting us at&nbsp;
                <a href="mailto:compliance@afgonline.com.au">privacy@finstead.com.au</a>.
            </p>
            <h3  style={{ whiteSpace: "pre-wrap" }}>
                <strong>Change in our privacy policy</strong>
            </h3 >
            <p style={{ whiteSpace: "pre-wrap" }}>
                We seek to remain relevant and up to date in all of our policies on privacy,
                this may mean this policy changes from time to time.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                You may request this privacy policy in a different format.
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>
                This Privacy Policy was last updated on&nbsp;<strong>1 June 2021</strong>.
            </p>
            <p
                className
                data-rte-preserve-empty="true"
                style={{ whiteSpace: "pre-wrap" }}
            />
            <p
                className
                data-rte-preserve-empty="true"
                style={{ whiteSpace: "pre-wrap" }}
            />
            <br/>
            <br/>
        </div>
    )
}

export default PrivacyPolicy;